<template>
  <div class="contacts">
    <div class="contacts__container _container">
      <BreadCrumbs :items="[[$t('main'), '/'], [$t('contacts')]]" />
      <h1 class="contacts__title main-title">{{ $t("contacts") }}</h1>
      <div class="contacts__body">
        <div class="contacts__content">
          <div class="contacts__block">
            <div class="contacts__item contacts__item_center">
              <div class="contacts__text">{{ $t("labels.retail") }}</div>
              <div class="contacts__group">
                <span class="contacts__icon"
                  ><img src="../assets/icons/footer/clock.svg" alt=""
                /></span>
                <span>{{ contacts.data[0].work_time }}</span>
              </div>
              <a :href="`tel:${contacts.data[0].phone_numbers[0].phone}`" class="contacts__group">
                <span class="contacts__icon"
                  ><img src="../assets/icons/footer/phone-call.svg" alt=""
                /></span>
                {{ contacts.data[0].phone_numbers[0].phone + ' ' + contacts.data[0].phone_numbers[0].city }}
              </a>
<!--              <a v-for="(item, id) in contacts.data[0].phone_numbers.slice(1)" :key="id" :href="`tel:${item.phone}`" class="contacts__group">-->
<!--                <span class="contacts__icon"-->
<!--                  ><img src="../assets/icons/footer/phone-call.svg" alt=""-->
<!--                /></span>-->
<!--                {{ item.phone + ' ' + item.city }}-->
<!--              </a>-->
              <div class="contacts__item">
                <div class="contacts__group">
                  <span class="contacts__icon"
                    ><img src="../assets/icons/footer/map.svg" alt=""
                  /></span>
                  <div class="contacts__address">{{ contacts.data[0].main_address }}</div>
                </div>
              </div>
            </div>
            <div class="contacts__item contacts__item_center">
              <div class="contacts__text">{{ $t("labels.wholesale") }}</div>
              <div class="contacts__group">
                <span class="contacts__icon"
                  ><img src="../assets/icons/footer/clock.svg" alt=""
                /></span>
                <span>{{ contacts.data[1].work_time }}</span>
              </div>
              <a :href="`tel:${contacts.data[1].phone_numbers[0].phone}`" class="contacts__group">
                <span class="contacts__icon"
                  ><img src="../assets/icons/footer/phone-call.svg" alt=""
                /></span>
                {{ contacts.data[1].phone_numbers[0].phone + ' ' + contacts.data[1].phone_numbers[1].city }}
              </a>
<!--              <a v-for="(item, id) in contacts.data[1].phone_numbers.slice(1)" :key="id" :href="`tel:${item.phone}`" class="contacts__group">-->
<!--                <span class="contacts__icon"-->
<!--                  ><img src="../assets/icons/footer/phone-call.svg" alt=""-->
<!--                /></span>-->
<!--                {{ item.phone + ' ' + item.city }}-->
<!--              </a>-->
              <div class="contacts__item">
                <div class="contacts__group">
                  <span class="contacts__icon"
                    ><img src="../assets/icons/footer/map.svg" alt=""
                  /></span>
                  <div class="contacts__address">{{ contacts.data[1].main_address }}</div>
                </div>
              </div>
            </div>
            <div class="contacts__item">
              <a :href="`mailto:${contacts.socials[0].link}`" class="contacts__group">
                <span class="contacts__icon"
                  ><img src="../assets/icons/footer/mail.svg" alt=""
                /></span>
                {{ contacts.socials[0].name }}
              </a>
            </div>
          </div>

          <div class="contacts__block">
            <div
              class="contacts__item"
              v-for="(contact, id) in citiesContacts"
              :key="id"
            >
              <a :href="`tel:${contact.phone}`" class="contacts__group">
                <span class="contacts__icon"
                  ><img src="../assets/icons/footer/phone-call.svg" alt=""
                /></span>
                {{ contact.phone }}
              </a>
              <span class="contacts__text">{{ contact.city }}</span>
            </div>
          </div>
        </div>
        <VeeForm
          ref="form"
          @submit="onSubmit"
          class="contacts__form contacts-form"
        >
          <h1 class="contacts-form__title second-title">
            {{ $t("app.askQuestion") }}
          </h1>
          <div class="contacts-form__items">
            <div class="contacts-form__item">
              <VeeField
                name="name"
                rules="required"
                type="text"
                class="contacts-form__input main-input"
                :placeholder="$t('placeholder.name')"
                v-model="request.name"
              />
              <transition name="error">
                <VeeErrorMessage
                  class="error-message"
                  name="name"
                ></VeeErrorMessage>
              </transition>
            </div>
            <div class="contacts-form__item">
              <VeeField
                name="email"
                type="text"
                rules="required|email"
                class="contacts-form__input main-input"
                placeholder="E-mail*"
                v-model="request.email"
              />
              <transition name="error">
                <VeeErrorMessage
                  class="error-message"
                  name="email"
                ></VeeErrorMessage>
              </transition>
            </div>
            <div class="contacts-form__item">
              <VeeField
                name="title"
                rules="required"
                type="text"
                class="contacts-form__input main-input"
                :placeholder="$t('placeholder.theme')"
                v-model="request.message"
              />
              <transition name="error">
                <VeeErrorMessage
                  class="error-message"
                  name="title"
                ></VeeErrorMessage>
              </transition>
            </div>
            <div class="contacts-form__item">
              <VeeField
                name="message"
                rules="required"
                v-model="request.text"
                v-slot="{ field }"
              >
                <textarea
                  class="contacts-form__textarea main-input"
                  :placeholder="$t('placeholder.answer')"
                  v-bind="field"
                  name="message"
                />
              </VeeField>
              <transition name="error">
                <VeeErrorMessage
                  class="error-message"
                  name="message"
                ></VeeErrorMessage>
              </transition>
            </div>
          </div>
          <button type="submit" class="contacts-form__btn main-btn">
            {{ $t("buttons.send") }}
          </button>
          <div class="contacts-form__accept">
            <VeeField
              name="privacy"
              rules="required"
              type="checkbox"
              v-model="request.privacy"
              id="privacy"
              value="accept"
              class="contacts-form__checkbox main-checkbox"
            />
            <label
              for="privacy"
              class="contacts-form__text"
              v-html="$t('uslovie')"
            >
            </label>
          </div>
          <transition name="error">
            <VeeErrorMessage
              class="error-message"
              name="privacy"
            ></VeeErrorMessage>
          </transition>
        </VeeForm>
      </div>
      <div class="contacts__additional">
        <h2 class="contacts__second-title second-title">{{ $t("map") }}</h2>
        <div class="contacts__map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1252.0137967869655!2d71.43361636107649!3d51.126399052924924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4245841c452ea5e5%3A0xdafc967b42e68013!2z0YPQuy4g0JTQvtGB0YLRi9C6IDEzLCDQkNGB0YLQsNC90LAgMDIwMDAw!5e0!3m2!1sru!2skz!4v1733334300577!5m2!1sru!2skz"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>

    <div v-if="isModalVisible" class="modal-overlay">
      <div class="modal">
        <h2 class="modal-title">{{ $t("successModal") }}</h2>
        <button class="modal-btn main-btn" @click="closeModal">{{ $t("continueBtn") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BreadCrumbs from "../components/UI/BreadCrumbs.vue";

export default {
  data() {
    return {
      request: {
        name: "",
        email: "",
        message: "",
        text: "",
        privacy: "",
      },
      isModalVisible: false,
    };
  },
  computed: {
    ...mapState(["contacts", "citiesContacts"]),
  },
  methods: {
    ...mapActions(["leaveRequest"]),
    async onSubmit() {
      try {
        await this.leaveRequest(this.request);

        this.request = {
          name: "",
          email: "",
          text: "",
          message: "",
          privacy: "",
        };

        this.$refs.form.resetForm();

        this.isModalVisible = true;
      } catch (error) {
        console.error("Failed to submit the form:", error);
      }
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  components: { BreadCrumbs },
};
</script>

<style lang="scss">
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 30px 10px;
  border-radius: 10px;
  text-align: center;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modal-title {
  font-size: 18px;
  margin-bottom: 20px;
}

.modal-btn {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  width: 70%;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.modal-btn:hover {
  background-color: #0056b3;
}

.contacts {
  padding: 50px 0 56px 0;
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0 89px 0;
  }

  &__title {
    margin: 0 0 39px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 30px 0;
    }
  }

  &__second-title {
    margin: 0 0 25px 0;
  }

  &__body {
    margin: 0 0 87px 0;
    @media (min-width: $mobile + px) {
      display: flex;
      gap: 0 153px;
      align-items: flex-start;
      margin: 0 0 47px 0;
    }

    @media (max-width: $pc + px) {
      gap: 0 80px;
    }

    @media (max-width: $tablet + px) {
      gap: 0 25px;
    }
  }

  &__content {
    margin: 0 0 78px 0;
    @media (min-width: $mobile + px) {
      display: flex;
      flex-wrap: wrap;
      gap: 42px 94px;
      margin: 0;
    }
  }

  &__block {
    &:first-child {
      flex: 0 1 100%;

      .contacts__group {
        white-space: normal;

        &:first-child {
          max-width: 350px;
          align-items: flex-start;
        }
      }
    }

    &:last-child {
      .contacts__item {
        display: flex;
        align-items: center;
        gap: 0 10px;
      }

      .contacts__text {
        font-weight: 500;
        margin: 0;
        color: #727272;
      }
    }

    &:not(:last-child) {
      @media (max-width: $mobile + px) {
        margin: 0 0 35px 0;
      }
    }
  }

  &__group {
    display: flex;
    align-items: center;
    gap: 0 5px;
  }

  &__item {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #202020;

    &:not(:last-child) {
      margin: 0 0 29px 0;
      @media (max-width: $pc + px) {
        margin: 0 0 20px 0;
      }
      @media (max-width: $mobile + px) {
        margin: 0 0 28px 0;
      }
    }
  }

  &__address {
    &:not(:last-child) {
      margin: 0 0 12px 0;
    }
  }

  &__text {
    color: #727272;
    margin: 10px 0 0 0;
  }

  &__icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__map {
    position: relative;
    width: 100%;
    height: 330px;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.contacts-form {
  flex: 0 0 540px;
  background: #ffffff;
  padding: 49px 80px 70px 100px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  @media (max-width: $tablet + px) {
    padding: 49px 30px 70px;
    flex: 0 0 440px;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 49px 9px 69px;
    margin: 0 0 87px 0;
  }

  &__title {
    margin: 0 0 28px 0;
    text-align: center;
  }

  &__items {
    margin: 0 0 32px 0;
  }

  &__item {
    &:not(:last-child) {
      margin: 0 0 15px 0;
    }
  }

  &__input {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #77757f;
  }

  &__textarea {
    width: 100%;
    height: 178px;
    resize: none;
  }

  &__btn {
    width: 100%;
    margin: 0 0 42px 0;
  }

  &__checkbox {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;

    &:after {
      background: url("../assets/icons/shopping-cart/check.svg") center
        no-repeat;
    }
  }

  &__accept {
    display: flex;
    align-items: flex-start;
    gap: 0 9px;
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
}
</style>